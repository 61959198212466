
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { getNextWebServerSidePropsWrapper, NextWebServerSideProps, Route } from '@web/routing';
import { Login } from '@web/templates/Login';
const getServerSideProps: NextWebServerSideProps<{}> = getNextWebServerSidePropsWrapper({}, Route.Login);
export const Home = Login;
export default Home;

    async function __Next_Translate__getServerSideProps__192d93fb007__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/login/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__192d93fb007__ as getServerSideProps }
  