import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { useContextData } from '@common/useContextData';
import { MetaHead } from '@web/organisms/MetaHead';

export const Login: FC = () => {
    const __meta = useTranslation('meta').t;
    const contextData = useContextData();
    return (
        <>
            <MetaHead
                title={`${__meta('label.login')} ${contextData.context.slug}`}
                description={__meta('login.description', {
                    domainName: contextData.context.name,
                })}
            />
        </>
    );
};
